body {
  margin: 0;
  font-family: "Catamaran", sans-serif;
  font-weight: 500;
}

.bottone-rosa,
.bottone-rosa:visited,
.bottone-rosa:active,
.bottone-rosa:hover,
.bottone-rosa:focus,
.bottone-rosa:link {
  background-color: #f2508b !important;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.wrapper {
  margin-bottom: 20vh;
  min-height: 100vh;
}

.footer {
  margin-top: -20vh;
  width: 100%;
  background-color: #f29544;
}

a.nav-link {
  margin-left: 20px;
  font-family: "Arima Madurai", cursive;
  font-weight: 700;
}

.fucsia {
  color: #f2508b;
}

.arancione {
  color: #f29544;
}

.arima-bold {
  font-family: "Arima Madurai", cursive;
  font-weight: 700;
}

.arima-medium {
  font-family: "Arima Madurai", cursive;
  font-weight: 500;
}

.catamaran-bold {
  font-family: "Catamaran", sans-serif;
  font-weight: 700;
}

.ragazzi_home {
  background-image: url("../public/immagini/ragazzi_home.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.secondo-div-home {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.terzo-div-home {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.quarto-div-home {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.quinto-div-home {
  padding-top: 5vh;
  padding-bottom: 8vh;
}

.secondo-div-home img {
  max-width: 300px;
}

.primo-div-ludoteca {
  background-image: url("../public/immagini/ludoteca.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.secondo-div-ludoteca {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.terzo-div-ludoteca {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.primo-div-chi-siamo {
  background-image: url("../public/immagini/header_chisiamo.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.secondo-div-chi-siamo {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.terzo-div-chi-siamo {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.quarto-div-chi-siamo {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.ultimo-div-chi-siamo {
  background-image: url("../public/immagini/footer-chisiamo.webp");
  padding-top: 15vh;
  padding-bottom: 15vh;
  background-size: cover;
  background-position: center;
}

.primo-div-cosapuoifare {
  background-image: url("../public/immagini/header-cosapuoifare.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.secondo-div-cosapuoifare {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.primo-div-cinquepermille {
  background-image: url("../public/immagini/cinquepermille.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.secondo-div-cinquepermille {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.terzo-div-cinquepermille {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.quarto-div-cinquepermille {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.quinto-div-cinquepermille {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.sesto-div-cinquepermille {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.immagini-cinquepermille {
  max-width: 300px;
  border-radius: 10px;
}

.primo-div-progetti {
  background-image: url("../public/immagini/header-progetti.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.secondo-div-progetti {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.terzo-div-progetti {
  background-image: url("../public/immagini/footer-progetti.webp");
  padding-top: 15vh;
  padding-bottom: 15vh;
  background-size: cover;
  background-position: center;
}

.primo-div-contatti {
  background-image: url("../public/immagini/header-contatti.png");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
}

.secondo-div-contatti {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.terzo-div-contatti {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.quarto-div-contatti {
  background-color: #f9f9f9;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.card-contatti {
  border: none;
  border-radius: 8px;
  background-color: #f2508b;
}

.input-card-contatto {
  border: none !important;
  border-radius: 12px !important;
}

.bottone-card-contatto {
  border: solid white 2px;
  border-radius: 12px !important;
  background-color: #f29544 !important;
  padding: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.testo-titolo-home {
  font-size: calc(1.5rem + 2vw);
}

.interlinea-50 {
  line-height: 40px;
}

.interlinea-30 {
  line-height: 30px;
}

.card-bordo-arancione {
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  border: solid #f29544 2px;
  border-radius: 12px;
}

.ultimo-div-home {
  background-image: url("../public/immagini/cosa_puoi_fare.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.ultimo-div-articolo {
  background-image: url("../public/immagini/cosa_puoi_fare.webp");
  padding-top: 25vh;
  padding-bottom: 25vh;
  background-size: cover;
  background-position: center;
}

.immagine-articolo {
  max-width: 90%;
  border-radius: 5px;
}

.immagine-social {
  max-width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

h6 {
  font-size: 1.15rem;
}

.container-sm {
  max-width: 850px;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.background-fiori {
  background-image: url("../public/immagini/fiorellini.png");
  background-size: cover;
  background-attachment: fixed;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}
